<template>
  <div>
    <v-container>
      <section class="py-12">
        <h1>
          {{ $t('azureAd') }}
          <img src="@/assets/images/azure-icon.png" width="50px" />
        </h1>
        <p>{{ $t('azureAdDescription') }}</p>
      </section>

      <section class="py-12">
        <h2>{{ $t('status') }}</h2>
        <v-switch
          v-model="adEnabled"
          :loading="adEnabledLoading"
          :disabled="adEnabledLoading"
          :label="adEnabled ? $t('enabled') : $t('disabled')"
          @change="setAdEnabled"
        />
      </section>

      <section class="py-12">
        <h2>{{ $t('authenticationKeys') }}</h2>
        <v-data-table
          :headers="adKeysHeaders"
          :items="adKeys"
          :loading="adKeysLoading"
          :hide-default-header="adKeys.length < 1"
          hide-default-footer
        >
          <template #[`item.key`]=""> ************** </template>

          <template #[`no-data`]="">
            <p class="text-left mx-n4">{{ $t('authenticationKeysNoData') }}</p>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-btn icon @click="promptDeleteKey(item)">
              <v-icon class="pa-1">fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-btn color="primary" @click="openAddKeyDialog">
          {{ $t('newKey') }}
        </v-btn>
      </section>

      <v-dialog v-model="addKeyDialog" max-width="600px">
        <v-card>
          <v-card-title>{{ $t('newAdKey') }}</v-card-title>
          <v-card-text>
            <div>{{ $t('generateAzureAdKeyDescription') }}</div>
            <div class="d-flex justify-center">
              <v-btn v-if="!newAdKeySecret" color="primary" @click="generateKey">
                {{ $t('generate') }}
              </v-btn>
              <v-textarea v-else rows="6" :value="newAdKeySecret" readonly>
                <template #append>
                  <v-btn icon @click="copyKey"><v-icon>fa-copy</v-icon></v-btn>
                </template>
              </v-textarea>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="closeAddKeyDialog">{{ $t('close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-snackbar v-model="copiedSnackbar" timeout="1000">
      {{ $t('copiedToClipboard') }}
    </v-snackbar>
  </div>
</template>

<script>
import getUserProfile from '@/util/getUserProfile';
import htmlMessageDialog from '@/components/dialog/htmlMessageDialog';
import router from '@/router';
import confirmDialog from '@/components/dialog/confirmDialog';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import moment from 'moment';

export default {
  name: 'AzureAdSettings',

  data: (self) => ({
    adEnabled: false,
    adEnabledLoading: false,

    adKeysHeaders: [
      {
        value: 'id',
        text: self.$t('id'),
      },
      {
        value: 'key',
        text: self.$t('key'),
      },
      {
        value: 'created',
        text: self.$t('created'),
      },
      {
        value: 'lastUsed',
        text: self.$t('lastUsed'),
      },
      {
        value: 'expires',
        text: self.$t('expires'),
      },
      {
        value: 'actions',
        text: self.$t('actions'),
      },
    ],
    adKeys: [],
    adKeysLoading: false,

    addKeyDialog: null,
    newAdKeySecret: '',

    copiedSnackbar: null,
  }),

  created() {
    this.checkAccess();
  },

  methods: {
    checkAccess() {
      getUserProfile().then((profile) => {
        if (!profile.features.find((feature) => feature.featureName?.toLowerCase() === 'azuread')) {
          htmlMessageDialog(
            this.$t('featureUnavailable'),
            this.$t('featureUnavailableDescription', null, {
              mail: 'sales@teamalert.net',
              featureName: this.$t('azureAd'),
            }),
            this.$t('ok'),
          )
            .open()
            .then(() => {
              router.replace('/');
            });
        } else {
          this.getAdEnabled();
          this.getAdKeys();
        }
      });
    },

    async getAdEnabled() {
      this.adEnabledLoading = true;
      try {
        const res = await api.getAdEnabled();
        this.adEnabled = res.enabled;
      } catch (error) {
        errorMessage(this.$t('error'), this.$t('errorGetAdEnabledMsg'), error);
      }

      this.adEnabledLoading = false;
    },

    async getAdKeys() {
      this.adKeysLoading = true;
      try {
        const res = await api.getAdKeys();
        res.adKeys.forEach((k) => {
          const format = 'lll';
          k.created = moment(k.created).format(format);
          k.lastUsed = moment(k.lastUsed).format(format);
          k.expires = k.expires ? moment(k.expires).format(format) : '-';
        });
        this.adKeys = res.adKeys;
      } catch (error) {
        errorMessage(this.$t('error'), this.$t('errorGetAdKeysMsg'), error);
      }

      this.adKeysLoading = false;
    },

    async setAdEnabled(enabled) {
      this.adEnabledLoading = true;

      const performSet = async () => {
        try {
          await api.setAdEnabled(enabled);
        } catch (error) {
          errorMessage(this.$t('error'), this.$t('errorSetAdEnabledMsg'), error);
        }
        this.adEnabledLoading = false;
      };

      if (!enabled) {
        const confirmed = await confirmDialog(
          this.$t('disableAzureAd'),
          this.$t('disableAzureAdMsg'),
          this.$t('cancel'),
          this.$t('disable'),
        ).open();

        if (confirmed) {
          await performSet();
        } else {
          this.adEnabled = true;
          this.adEnabledLoading = false;
        }
      } else {
        await performSet();
      }
    },

    async promptDeleteKey(key) {
      const confirmed = await confirmDialog(
        this.$t('deleteKey'),
        this.$t('deleteKeyMsg', null, {
          lastUsed: key.lastUsed,
        }),
        this.$t('cancel'),
        this.$t('delete'),
      ).open();

      if (confirmed) {
        try {
          await api.deleteAdKey(key.id);
          this.getAdKeys();
        } catch (error) {
          errorMessage(this.$t('error'), this.$t('errorDeleteAdKeyMsg'), error);
        }
      }
    },

    openAddKeyDialog() {
      this.addKeyDialog = true;
    },

    closeAddKeyDialog() {
      this.addKeyDialog = false;
      this.newAdKeySecret = '';
    },

    async generateKey() {
      try {
        const res = await api.generateAdKey();
        this.newAdKeySecret = res.adKeySecret;
        this.getAdKeys();
      } catch (error) {
        errorMessage(this.$t('error'), this.$t('errorGenerateAdKeyMsg'), error);
      }
    },

    async copyKey() {
      await navigator.clipboard.writeText(this.newAdKeySecret);
      this.copiedSnackbar = true;
    },
  },
};
</script>

<style></style>
